<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
       y="0px"
       width="432.554px" height="432.554px" viewBox="0 0 432.554 432.554"
       style="enable-background:new 0 0 432.554 432.554;"
       xml:space="preserve" stroke="purple" fill="purple">
    <g>
      <g>
        <path d="M339.53,294.39c-40.824-15.701-52.602-20.406-56.525-21.195c-0.789,0-2.352,0-3.142,0.789l-36.9,25.114
          c-0.781,0.79-1.562,1.571-1.562,3.142c0,0.782,0,2.353,0.781,3.143l18.843,18.839l-21.195,21.195
          c-1.574,1.574-1.574,3.927,0,5.498l18.835,24.333l-25.903,51.816c-0.782,1.566-0.782,2.355,0,3.927
          c0.789,0.781,2.352,1.563,3.142,1.563h157.007c2.356,0,3.928-1.563,3.928-3.924C398.407,410.573,403.897,319.512,339.53,294.39z"
        />
        <path d="M173.885,374.466l18.841-24.342c1.571-1.562,0.786-3.927,0-5.49l-21.197-21.195l18.843-18.847
          c0.782-0.781,1.569-2.353,0.782-3.134c0-0.789-0.782-2.36-1.567-3.142l-36.898-25.122c-0.786-0.786-2.354-0.786-3.14-0.786
          c-3.14,0.786-14.913,5.498-56.522,21.199C28.654,319.512,34.146,410.573,35.719,428.63c0,2.36,1.571,3.924,3.923,3.924h157.009
          l0,0c1.571,0,2.353-0.782,3.14-1.563c0.786-0.789,0.786-2.36,0-3.927L173.885,374.466z"/>
        <path d="M242.962,373.676l-21.19-26.686l20.409-20.417c0.781-0.782,0.781-1.563,0.781-3.135c0-1.574-0.781-2.355-0.781-3.146
          l-22.767-22.762c-1.566-1.571-3.925-1.571-5.492,0l-22.77,22.762c-1.567,1.571-1.567,3.928,0,5.498l20.412,20.406l-21.193,26.697
          c-0.785,0.781-0.785,2.353-0.785,3.924l23.551,51.812c0.786,1.571,2.353,2.36,3.926,2.36c1.566,0,3.138-0.789,3.925-2.36
          l23.546-51.812C244.534,376.819,244.534,375.248,242.962,373.676z"/>
        <path d="M297.917,140.522c-20.41-10.99-51.026-17.268-81.639-17.268c-30.617,0-61.233,6.278-81.643,17.268
          c-0.788,0.786-1.573,1.571-1.573,2.356c0,0.784,0,2.354,0.786,3.14c17.274,26.692,63.59,27.477,73.007,27.477l0,0h20.407
          c9.422,0,55.743-1.573,73.016-27.477c0.781-0.785,0.781-2.356,0.781-3.14C299.488,142.093,298.706,140.522,297.917,140.522z"/>
        <path d="M216.277,261.417L216.277,261.417L216.277,261.417c52.597-0.781,94.984-43.176,94.984-95.771c0-1.571,0-3.925,0-6.282
          c0-1.569-0.781-3.14-2.353-3.14c-1.57-0.788-3.142,0-3.923,0.786c-22.771,22.764-64.376,25.122-76.935,25.122h-21.197
          c-12.558,0-54.167-1.571-76.933-25.122c-0.786-0.786-2.353-1.573-3.923-0.786c-1.573,0.786-2.354,1.571-2.354,3.14
          c0,2.356,0,3.923,0,6.282C121.285,218.241,163.678,260.635,216.277,261.417z"/>
        <path d="M114.222,108.335c1.571,0.786,3.138,1.571,4.711,0.786c14.128-7.851,58.091-21.193,96.557-21.193
          c39.247,0,83.216,13.343,97.347,21.193c0.781,0,1.57,0.785,1.57,0.785c0.782,0,1.571,0,2.353-0.785
          c9.422-8.636,16.482-14.914,18.843-17.269c1.567-1.571,1.567-3.925,0.782-5.496c-0.782-0.787-1.571-1.573-2.353-3.14
          c-3.924-3.925-9.422-10.209-11.774-20.412l-1.574-5.498c-1.571-14.128-2.353-19.624-16.483-21.193h-3.923
          c-14.92-1.571-38.472-3.925-57.314-20.412C227.261,1.573,220.988,0,216.277,0c-4.713,0-10.991,1.573-26.691,15.701
          c-18.841,16.487-42.391,18.841-57.31,20.412h-3.926c-14.128,1.569-15.701,7.065-18.053,20.412l-1.573,5.494
          c-2.353,10.207-7.851,15.701-11.776,20.412c-0.782,0.785-1.567,2.352-2.353,3.138c-1.571,1.573-0.786,3.925,0.786,5.498
          C98.521,93.419,104.799,99.703,114.222,108.335z"/>
        <path d="M307.338,131.103c0.789-0.785,1.57-1.571,2.352-3.142l1.571-7.851c0-1.569-0.781-3.925-2.353-3.925
          c-10.992-4.707-50.245-18.841-91.846-18.841c-42.393,0-80.862,14.134-91.853,18.841c-1.567,0.785-2.353,2.356-2.353,3.925
          l1.567,7.851c0,1.571,0.785,2.356,2.358,3.142c0.785,0,0.785,0.783,1.566,0.783c0.786,0,1.571,0,1.571-0.783
          c22.766-10.992,53.383-17.272,87.927-17.272c33.755,0,65.156,6.28,87.919,17.272C304.986,131.103,306.557,131.103,307.338,131.103
          z"/>
      </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ChaserIcon"
}
</script>

<style scoped>

</style>